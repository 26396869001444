import React from "react";
import { Typography } from "antd";
import { ExclamationCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ReactComponent as WarningIcon } from '../assets/svg/warning.svg';

const ERROR_COLOR = "#0A1339";
const SUCCESS_COLOR = "#FDB415";

const iconStyle = { fontSize: 50, borderRadius: 30 };
const titleStyle = { textAlign: 'center'}
const failStyle = {color: '#FF4D4F', fontSize: 70};
let imgStyle = {width: 100, height: 100};

interface ModalContentProps {
  title?: string;
  content: HTMLElement;
  type: "success" | "error";
}

const { Title } = Typography;

const ModalError = () => {
  return (
    <>
        <WarningIcon style={imgStyle}/>
        <h2 className="text-secondary">Error</h2>
    </>
  );
};

const ModalSuccess = () => {
    return (
      <>
        <div id="icon-container">
          <div>
            <CheckCircleFilled
              style={{ ...iconStyle, color: SUCCESS_COLOR }}
            />
          </div>
        </div>
        <Title level={3} style={{ textAlign: 'center', color: SUCCESS_COLOR}}>
          Success
        </Title>
      </>
    );
  };

const ModalContent = (props: ModalContentProps) => {
  let RenderModalType;
  switch (props.type) {
    case "error":
      RenderModalType = ModalError;
      break;
    case "success":
      RenderModalType = ModalSuccess;
      break;
  }
  return ( 
    <div className="dynamic-info text-center text-secondary ">
      {RenderModalType && <RenderModalType />}
      <p className="fw-500 text-gray modal-error-content">{props.content}</p>
    </div>
  );
};

export default ModalContent;
